import React from "react";
import { useUserProfile } from "./useUserProfile";
import Auth from "../../auth/auth";

export const useCheckAuthWithPermission = (
  applicationId: string,
  permissions: string | string[],
  locationId?: string
) => {
  const profile = useUserProfile();

  const siteId = React.useMemo(
    () => profile?.preferences?.selectedSiteId || profile?.siteId,
    [profile]
  );

  if (!siteId) {
    return null;
  }

  const hasAuth = React.useMemo(() => {
    const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];
    return Auth.checkPermission({
      applicationId,
      permissions: permissionsArray,
      siteId,
      locationId
    });
  }, [applicationId, permissions, siteId, locationId]);

  return hasAuth;
};
