import { Application } from "@nutrien-operations/config";

export const basePath = "/calculation-engine";

const permissions = {
  configureCalculations: "965585ca-1c5d-4ef8-b8b1-19b0ec8bc1e7",
  deleteCalculations: "822ab426-7ee7-4c4b-a7fb-7b5a609c5873",
  viewCalculations: "9135760b-bfeb-42ef-83d3-4cc27c724736"
};

const applicationConfig: Application = {
  applicationId: "dc4099ed-ff63-4006-863c-bd6f855a53dd",
  name: "@nutrien-operations/calculation-engine",
  displayName: "Calculation Engine",
  icon: "hardDrive",
  path: {
    value: basePath
  },
  order: 1,
  permissions,
  menu: {
    label: "Dashboard",
    path: basePath,
    icon: "hardDrive",
    children: [
      {
        label: "Calculations",
        path: "/",
        permission: permissions.viewCalculations
      },
      {
        label: "Admin",
        path: "/admin",
        permission: [permissions.configureCalculations]
      }
    ]
  },
  swagger: {
    localdev: { url: "https://dev-calculations-swagger-ui.np.nutrien.com" },
    development: { url: "https://dev-calculations-swagger-ui.np.nutrien.com" },
    uat: {
      url: "https://uat-calculations-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-calculations-swagger-ui.np.nutrien.com"
    }
  }
};

export default applicationConfig;
