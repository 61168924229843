import { useMutation, useQueryClient } from "react-query";

import { CalculationsEngineApi, CalculationsListResponse } from "../../..";

import { calculationsByLocationId } from "../query-cache";

export const useDeleteCalculation = (locationId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (calculationId: string) => {
      return CalculationsEngineApi.Calculations.deleteCalculationById(calculationId);
    },
    onSuccess: (_response, calculationId) => {
      const queryKey = calculationsByLocationId(locationId);

      queryClient.setQueryData<CalculationsListResponse | undefined>(
        queryKey,
        (currentState: CalculationsListResponse | undefined) => {
          if (!currentState) return undefined;

          // Delete
          const nextCalculations = currentState.data.filter((state) => {
            return state.calculationId !== calculationId;
          });

          return {
            ...currentState,
            data: [...nextCalculations]
          };
        }
      );
    }
  });

  return {
    deleteCalculation: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
