import { useMutation, useQueryClient } from "react-query";

import { CalculationsEngineApi, useGetCalculationById } from "../../..";
import { Calculation, HTTPError } from "../../../types";
import { calculationsByLocationId } from "../query-cache";

type Variables = {
  updates: Partial<Calculation> | Calculation;
};

export const useUpdateCalculation = (calculationId: string, locationId: string) => {
  const queryClient = useQueryClient();

  const { calculation } = useGetCalculationById(calculationId);

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation(
    ({ updates }: Variables) =>
      CalculationsEngineApi.Calculations.updateCalculation(calculationId, calculation, updates),
    {
      onSuccess: () => {
        const queryKey = calculationsByLocationId(locationId);

        queryClient.invalidateQueries<Partial<Calculation>>(queryKey, {
          exact: true
        });
      },
      onError: async (err: HTTPError) => {
        let message = "Failed to update calculation, please try again";
        if (err.response?.status) {
          try {
            const data = await err.response.json();
            message = data?.error;
          } catch {
            message = "Undetermined error";
            throw err;
          }
        }
        err.message = message;
      }
    }
  );

  return {
    updateCalculation: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    calculation: data,
    error
  };
};
