import { useQuery } from "react-query";

import { CalculationsEngineApi, HTTPError } from "../../..";

import { calculationExecutedByIdQueryKey } from "../query-cache";

export const useExecuteCalculation = (
  calculationId: string,
  date: string,
  allowOutput: boolean
) => {
  const queryKey = calculationExecutedByIdQueryKey(calculationId);

  const { data, isLoading, isFetching, refetch, isError, isSuccess, error } = useQuery(
    queryKey,
    () => CalculationsEngineApi.Calculations.executeCalculation(calculationId, date, allowOutput),
    {
      onError: async (err: HTTPError) => {
        let message = "Failed to execute, please try again";
        if (err.response?.status) {
          try {
            const data = await err.response.json();
            message = data?.error;
          } catch {
            message = "Undetermined error";
            throw err;
          }
        }
        err.message = message;
      },
      retry: 0,
      enabled: false,
      keepPreviousData: false
    }
  );

  return {
    calculation: data?.data || [],
    error,
    isExecutingCalculation: isFetching,
    isSuccess,
    isError,
    isLoadingCalculationAfterExecution: isLoading,
    executeCalculation: refetch
  };
};
