import { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SpinningLoader } from "@packages/theme-mui-v5";
import ApplicationAccessManagement from "@packages/application-access-management";
import applicationConfig, { basePath } from "./config";

const DeleteCalculation = SpinningLoader(
  lazy(() => import("./drawers/calculations/DeleteCalculation"))
);
const AddEditCalculation = SpinningLoader(
  lazy(() => import("./drawers/calculations/AddEditCalculation"))
);

const FormulaEditorContainer = SpinningLoader(lazy(() => import("./pages/FormulaEditorContainer")));
const ViewCalculations = SpinningLoader(lazy(() => import("./pages/ViewCalculations")));
const ViewCalculationResults = SpinningLoader(lazy(() => import("./pages/ViewCalculationResults")));

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route path="calculations/new" element={<AddEditCalculation />} />

        <Route path="calculations/:calculationId/formula" element={<FormulaEditorContainer />} />
        <Route path="calculations/:calculationId/results" element={<ViewCalculationResults />} />

        <Route path="calculations" element={<ViewCalculations />}>
          <Route path=":calculationId/edit" element={<AddEditCalculation />} />
          <Route path=":calculationId/delete" element={<DeleteCalculation />} />
        </Route>

        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId={applicationConfig.applicationId}
            />
          }
        />
        <Route path="*" element={<Navigate to="/calculations" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
