import { useQuery } from "react-query";

import { CalculationsEngineApi } from "../../..";

import { calculationByIdQueryKey } from "../query-cache";

export const useGetCalculationById = (calculationId: string) => {
  const queryKey = calculationByIdQueryKey(calculationId);

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryKey,
    () => CalculationsEngineApi.Calculations.getCalculationById(calculationId),
    {
      enabled: !!calculationId
    }
  );

  return {
    calculation: data?.data,
    error,
    isFetching,
    loading: isLoading,
    refetch
  };
};
