import { useQuery } from "react-query";
import { ListOfValuesApi } from "../../..";
import { listSchemaItemsByIdOrKeyQueryKey } from "../query-cache";

export const useListSchemaItemsByIdOrKey = (idOrKey: string) => {
  return useQuery(
    listSchemaItemsByIdOrKeyQueryKey(idOrKey),
    () => ListOfValuesApi.Schemas.listSchemaItemsByIdOrKey(idOrKey),
    {
      enabled: !!idOrKey
    }
  );
};
