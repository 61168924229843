import { useMutation, useQueryClient } from "react-query";

import { CalculationsEngineApi } from "../../..";
import { Calculation, CalculationDocumentResponse, HTTPError } from "../../../types";
import { calculationsByLocationId } from "../query-cache";

export const useCreateCalculation = (locationId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (calculation: Partial<Calculation>) => {
      return CalculationsEngineApi.Calculations.createCalculation(calculation);
    },
    onSuccess: (_calculation: CalculationDocumentResponse) => {
      const queryKey = calculationsByLocationId(locationId);

      queryClient.invalidateQueries<CalculationDocumentResponse>(queryKey, {
        exact: true
      });
    },
    onError: async (err: HTTPError) => {
      let message = "Unfortunately, We are unable to save your changes. Please try again.";
      if (err.response?.status) {
        try {
          const data = await err.response.json();
          message = data?.error;
        } catch {
          message = "Undetermined error";
        }
      }
      err.message = message;
    }
  });

  return {
    createCalculation: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    calculation: data?.data,
    error
  };
};
