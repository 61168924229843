import { QueryKeyFunction } from "../../types";

export const calculationsByLocationId: QueryKeyFunction = (locationId: string) => [
  "calculations",
  { locationId }
];

export const calculationsListQueryKey: QueryKeyFunction = () => ["calculations", "list"];

export const calculationByIdQueryKey: QueryKeyFunction = (calculationId: string) => [
  "calculations",
  "single",
  calculationId
];

export const calculationResultByIdQueryKey: QueryKeyFunction = (calculationId: string) => [
  "calculations",
  "results",
  calculationId
];

export const calculationExecutedByIdQueryKey: QueryKeyFunction = (calculationId: string) => [
  "calculations",
  "executed",
  calculationId
];
